<style>
.el-dialog__title {
  font-weight: bold;
}
.tab_Top {
  width: 100%;
  /* height: 150px; */
  background: #ffffff;
  padding-left: 26px;
  /* margin-top: 5px; */
  padding: 30px 26px;
}
.el-menu-demo {
  background: #1890ff;
  display: inline-block;
  border-radius: 50px;
}
.Men_1 {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 14px;

   
  font-weight: 400;
  color: #8c8c8c;
  margin-top: 10px;
}
.el-menu-demo {
  height: 40px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  color: #0081ff;
}
.el-menu--horizontal>.el-menu-item.is-active {
  border: 0;
}
.el-menu--horizontal > .el-menu-item {
  height: 36px;
  line-height: 36px;
  border-radius: 50px;
  margin: 2px 2px 0 2px;
  color: #fff;
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  /* border: 1px solid #fff; */
  color: #0081ff;
  background: #fff;
  border-radius: 50px;
  height: 36px;
  line-height: 36px;
  margin: 2px 2px 0 2px;
}
.Tables {
  width: 94.6%;
  height: auto;
  background: #fff;
  margin: 24px;
  margin-top: 10px;
  padding: 24px;
  overflow: hidden;
}
.Tab_T {
  width: 124px;
  height: 32px;
  background: #0081ff;
  border-radius: 3px;
  text-align: center;
  line-height: 32px;
  font-size: 14px;
   
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 24px;
  float: left;
  margin-right: 20px;
}
.Tab_T i {
  margin-right: 5px;
}
.Tables .dsds .el-input__inner {
  width: 239px;
  height: 32px;
  float: right;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Tables .dsds .el-input {
  float: right;
  width: 30%;
}
.Tables .el_seso {
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  cursor: pointer;
}
.Tables .el_seso i {
  color: #8c8c8c;
}
.el-pagination {
    float: right;
    margin-top: 28px;
}
.el_butto {
    display: inline-block;
}
.el_butto .el-button {
    width: 68px;
    height: 32px;
    background: #1890FF;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
.rere .el-button {
    background: #FF524D;
    margin-left: 10px;
}
.show .rightPanel-background {
    z-index: 200;
    opacity: 1;
    width: 100%;
    height: 100%;
}
.rightPanel-background{
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
}
.rightPanel {
    width: 100%;
    max-width: 656px;
    height: 1400px;
    top: 0;
    right: 0;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 5%);
    -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    -webkit-transform: translate(0%);
    transform: translate(0%);
    background: #fff;
    z-index: 400;
}
.Rig_1 {
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding-left: 24px;
    font-size: 16px;
     
    font-weight: bold;
    color: #262626;
    margin-bottom: 5px;
}
.Rig_A {
    margin-left: 24px;
}
.Rig_D {
    margin-bottom: 20px;
    overflow: hidden;
}
.Rig_Ddd {
    font-size: 14px;
     
    font-weight: 400;
    color: #333333;
    width: 158px;
    float: left;
    text-align: left;
    /* margin-top: 11px; */
    margin-right: 13px;
}
.rightPanel .el-input {
    width: 293px;
    /* height: 32px; */
    background: #FFFFFF;
    border-radius: 3px;
}
.BCSZ {
    width: 88px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    background: #0081FF;
    border-radius: 3px;
    font-size: 14px;
     
    font-weight: 400;
    color: #FFFFFF;
    margin: 20px 6px 0;
    float: right;
}

.el-input__inner, .el-textarea__inner {
    height: 32px;
    line-height: 32px;
    width: 293px;
}
.el-radio {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: none;
    font-size: 14px;
    margin-right: 18px;
}
.Riot {
  overflow: hidden;
  clear: both;
  margin-top: 20px;
}
.Riot div {
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.Rig_y div:last-child {
  font-size: 14px;
   
  font-weight: 400;
  color: #8C8C8C;
  margin-left: 14px;
  margin-top: 8px;
}
.Rig_y div:first-child{
  width: 80px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #0081FF;
  border-radius: 3px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
   
  font-weight: 400;
  color: #0081FF;
}
.Rig_y div {
  float: left;
}
.tab_Bor {
    margin: 10px 24px 0;
    width: 100%;
    /* height: 187px; */
    background: #FFFFFF;
    border-radius: 4px;
    padding: 2px 24px;
}
.tab_Bor div {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #262626;
}
.T1 {
    height: 61px;
    line-height: 61px;
    overflow: hidden;
}
.bord {
    border-bottom: 1px dashed #E8E8E8;
}
/* .el-button:focus, .el-button:hover {
  background: none;
  color: #262626;
} */
.T1 .el-button {
    border: none;
    padding: 0;
    float: left;
    margin-top: 10px;
    margin-right: 10px;
}
.T2 div {
    float: left;
    margin-right: 30px;
    cursor: pointer;
}
.T2 div:last-child {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #1890FF;
}
.rightPanel .Tigtgg .el-input{
  width: 608px!important;
}
.rightPanel .Tigtgg .el-input__inner, .el-textarea__inner {
    width: 608px!important;
}
.Rig_D textarea {
  resize:none;
  width: 608px;
  height: 100px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.el-upload--picture-card i {
  color: #BFBFBF;
}
.el-upload--picture-card {
  border: 1px dashed #D9D9D9;
  background-color:#fff;
  position: relative;
}
.el_asff {
  position: absolute;
  top: -4%;
    left: 21%;
    font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #BFBFBF;
}
.el-upload-list--picture-card .el-upload-list__item {
    width: 96px;
  height: 96px;
}
.el-upload--picture-card {
  width: 96px;
  height: 96px;
}
.el-upload--picture-card i {
  position: absolute;
  left: 37%;
  top: 25%;
}
.TUUu .el-upload--picture-card {
  cursor:auto
}
.TUUu .el-upload--picture-card:hover, .el-upload:focus {
  border-color: #D9D9D9;
  color: #D9D9D9;
}
.R_texxt textarea {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  line-height: 22px;
}



.rightPanel .Tigtgg .Riuo .el-input {
    width: 132px!important;
}
.rightPanel .Tigtgg .Riuo .el-input__inner {
  width: 132px!important;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
}
.el-input__icon {
  /* line-height: 0; */
  line-height: 40px;
}
.item-index {
  line-height: 12px;
  font-size: 13px;
  position: relative;
  color: #8C8C8C;
}
.TRRE .item::after {
    content: "";
    width: 1px;
    height: calc(100% + 10px); /*加上10px是item底部的margin*/
    background-color: #D9D9D9;
    position: absolute;
    top: 14px;
    left: -11px;
}
.TRRE #time-line div:last-child::after  {
    width: 0px;
}
.TRRE .item::before {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 1px solid #D9D9D9;
  position: absolute;
  left: -15px;
}
.TRRE .item {
  margin-left: 15px; /*用左边margin显示时间线*/
  width: calc(100% - 30px); /*因为左边部分用于显示时间线，所以右边部分减去30px*/
  height: auto; /*高度由内容决定*/
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
}
.TRRE .item-content {
  width: 100%;
  height: auto; /*由内容决定*/
  position: relative;
  white-space: pre-wrap; /*保留空白符序列，但是正常地进行换行*/
  word-wrap: break-word; /*在长单词或 URL 地址内部进行换行*/
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  line-height: 22px;
  margin-top: 8px;
}
.TRRE .ytyt {
  margin:27px 0 15px 8px ;
}
.TRRE .el-image {
  width: 60px;
  height: 60px;
  display: inline-block;
  margin-right: 12px;
  margin-top: 12px;
}
.El_pop .el-input__inner {
  width: 230px;
  height: 32px;
  background: #F5F5F5;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
}
.dsdtrt .el-icon-delete {
  cursor: pointer;
}
.dsdtrt .el-icon-delete {
  font-size: 23px;
  color: #BFBFBF;
}
.dsdtrt .el-icon-delete:hover {
  font-size: 23px;
  color: #FF4D4F!important;
}
.dialog-footer {
  text-align: right;
}
.Dityo {
  margin: 0;
  padding: 0;
  margin-top: 25px;
  clear: both;

}
.Dityo .el-button {
  min-height: 34px;
  padding: 8px 20px;
}
.dasdasd .el-input__inner {
  background: #fff;
}
.Y_ids {
  height: 54px;
  line-height: 54px;
  box-shadow: 0px -1px 0px 0px #E8E8E8;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #1890FF;
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
}
.Y_ids i {
  width: 14px;
  height: 14px;
  margin: 0 8px 0 14px;
}
.T_ov {
  padding: 0;
  margin: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.Ta_nan {
  float: left;
  width: 70px;
  text-align: right;
  margin-right: 12px;
  margin-top: 10px;
}
.Tacc .el-input {
  float: left;
  width: 395px;
}
.Tacc .el-input__inner {
  width: 395px;
  line-height: 32px;
}
.Ty_as {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #BFBFBF;
  margin-left: 80px;
}
.Fince .Rig_Rrr {
  font-size: 14px;
   
  font-weight: 400;
  color: #0081FF;
  float: right;
  margin-right: 25px;
}
.el-input__suffix, .el-input__icon {
  line-height: 32px;
}
.el-col-11 {
    max-width: 45.8333333333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45.8333333333%;
    flex: 0 0 45.8333333333%;
}
.Topo .el-form-item__content .el-input__inner {
    width: 155px!important;
}
.rightPanel .el-input {
    /* height: 32px; */
    background: #FFFFFF;
    border-radius: 3px;
}
.T_sele {
    float: left;
    margin-right: 10px;
}
.T_sele .el-input--medium .el-input__inner {
    width: 102px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}
.Tab_T {
    width: 124px;
    height: 32px;
    background: #0081FF;
    border-radius: 3px;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
     
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 24px;
    float: left;
    margin-right: 20px;
}
.Tab_y {
    width: 76px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    color: #595959;
}
.T_sele .el-input__inner {
    width: 102px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
}
.block .el-input__inner, .el-textarea__inner {
  width: 100%!important;
}
.laushd {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-top: 18px;
  margin-bottom: 15px;
}
</style>

<template>
  <div>
    <div class="tab_Top Fince">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect_top">
        <el-menu-item index="1">销售线索</el-menu-item>
        <el-menu-item index="2">客户管理</el-menu-item>
        <el-menu-item index="3">订单管理</el-menu-item>
        <el-menu-item index="4">合同管理</el-menu-item>
        <el-menu-item index="5">数据报表</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
      <!-- <div class="Men_1">班次设置：不同班次有不同的上班时间、下班时间、午休时间以及迟到多久算严重等相关信息。</div> -->
      <!-- <div class="Men_1">考勤组设置：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
      <div class="Men_1">考勤组设置：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div> -->
    </div>
    <div class="tab_Bor"> 
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
          <el-button>线索来源<i class="el-icon-question"></i>:</el-button>
        </el-tooltip>
        <div class="T2" style="margin-left:24px">
          <div>类别</div>
          <div>二建名单</div>
          <div>抖音线索</div>
          <div>百度推广</div>
          <div @click="dialogVisible3 = true">添加线索来源</div>
        </div>
      </div>
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
          <el-button>线索需求<i class="el-icon-question" ></i>:</el-button>
        </el-tooltip>
        <div class="T2" style="margin-left:24px">
          <div>类别</div>
          <div>二建名单</div>
          <div>抖音线索</div>
          <div>百度推广</div>
          <div>添加线索来源</div>
        </div>
      </div>
      <div class="T1 bord">
        <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
          <el-button>线索价值<i class="el-icon-question"></i>:</el-button>
        </el-tooltip>
        <div class="T2" style="margin-left:24px">
          <div>类别</div>
          <div>二建名单</div>
          <div>抖音线索</div>
          <div>百度推广</div>
          <div @click="AddXs()">添加线索来源123</div>
        </div>
      </div>
      <div class="T1">
        <el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
          <el-button>线索价值<i class="el-icon-question"></i>:</el-button>
        </el-tooltip>
        <div style="float:left;margin-top:16px;width:362px" class="Topo">
          <el-form ref="form" :model="sizeForm" label-width="6px" size="mini">
            <el-form-item label="">
              <el-col :span="11">
                <el-time-picker placeholder="开始日期" v-model="sizeForm2" style="width: 100%;"></el-time-picker>
              </el-col>
              <el-col class="line" :span="2" style="margin:0 9px">至</el-col>
              <el-col :span="11">
                <el-time-picker placeholder="结束日期" v-model="sizeForm2" style="width: 100%;"></el-time-picker>
              </el-col>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    
    <div class="Tables">
      <div class="T_sele">
        <el-select v-model="value" clearable placeholder="我的线索">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="Tab_T"><i class="el-icon-plus"></i>创建线索</div>
      <div class="Tab_T Tab_y"><i class="el-icon-plus"></i>导出</div>
      <div class="Tab_T Tab_y"><i class="el-icon-plus"></i>导入</div>
      <div class="Tab_T Tab_y" style="width:108px"><i class="el-icon-plus"></i>批量认领</div>
      <div class="Tab_T Tab_y" style="width:108px;border: 1px solid #FF4D4F;color:#FF4D4F"><i class="el-icon-plus"></i>批量删除</div>

      <div class="dsds">
        <el-input v-model="input" placeholder="请输入内容"></el-input>
      </div>
      
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column
          type="selection"
          width="55"
          align="center"></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"></el-table-column>
        <el-table-column prop="name" label="考勤组名称" width="180"></el-table-column>
        <el-table-column prop="people" label="考勤负责人" width="140"></el-table-column>
        <el-table-column prop="banci" label="班次" width="140"></el-table-column>
        <el-table-column prop="time" label="考勤时间" width="440"></el-table-column>
        <el-table-column prop="num" label="考勤人数" width="180"></el-table-column>
        <el-table-column prop="fangs" label="考勤方式" width="180"></el-table-column>
        <el-table-column label="操作" width="245" align="center" >
          <template #default="scope" >
              <div class="el_butto">
                <el-button
                type="text"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button>
              </div>
            <div class="el_butto rere">
                <el-button
                type="text"
                class="red"
                @click="handleDelete(scope.$index, scope.row)"
                >删除</el-button>
            </div>

          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="40">
        </el-pagination>
      </div>
    </div>
    <div class="container">
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </div>

    <!-- 创建客户 -->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 656px;position: fixed;top: 0;right: 0;z-index:99999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel">
                <div class="Rig_1">创建客户<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div class="Rig_A">
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px"> 
                              <span style="color:#FF4D4F">* </span>姓名</div>
                            <div class="Rtty">
                              <el-input v-model="input" placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 性别</div>
                            <el-select v-model="value1" placeholder="请选择">
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 联系方式</div>
                            <div class="Rtty">
                              <el-input v-model="input" placeholder="请填写客户手机号码" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                    <div style="width:50%;float:left">
                        <div class="Rig_D ">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 微信号（选填）</div>
                            <div class="Rtty">
                              <el-input v-model="input" placeholder="请填写客户微信号" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                  <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 工作单位（选填）</div>
                            <div class="Rtty">
                              <el-input v-model="input" placeholder="请填写线索客户工作单位" class="El_ii"></el-input>
                            </div>
                        </div>
                  </div>
                </div>
                <div class="laushd">来源信息</div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 客户来源</div>
                            <el-select v-model="value1" placeholder="请选择">
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>  
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 客户价值</div>
                            <el-select v-model="value1" placeholder="请选择">
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div style="overflow:hidden">
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px">办理时间（选填）</div>
                            <div class="Rtty">
                              <el-input v-model="input" placeholder="请选择办理时间" class="El_ii"></el-input>
                            </div>
                        </div>
                    </div>  
                    <div style="width:50%;float:left">
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:8px">报单情况</div>
                            <el-select v-model="value1" placeholder="请选择">
                              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="Rig_D Tigtgg" >
                  <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 需求项目（可多选）</div>
                  <div class="Rtty" style="clear:both">
                    <el-select v-model="value1" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                </div> 
                <div class="Rig_D Tigtgg" >
                  <div class="Rig_Ddd" style="margin-bottom:9px">客户标签（可多选）</div>
                  <div class="Rtty" style="clear:both">
                    <el-select v-model="value1" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                  </div>
                </div> 
                <div class="laushd" style="margin-top:28px">附件</div>
                <div class="Rig_D">
                    <div class="Rig_Ddd" style="margin-bottom:9px">描述（选填）</div>
                    <div class="Rtty">
                        <el-input type="textarea" style="" v-model="form.desc"></el-input>           
                    </div>    
                </div>
                <div>
                  <div class="Rig_Ddd" style="margin-bottom:9px">图片线索（选填）</div>
                  <div class="Rtty" style="clear:both">
                    <el-upload
                      action="https://jsonplaceholder.typicode.com/posts/"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove">
                      <i class="el-icon-plus"></i>
                      <div class="el_asff">上传图片</div>
                    </el-upload>
                    <el-dialog>
                      <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                  </div>
                </div>
                <div class="BCSZ" style="margin-right:24px">保存设置</div>
                <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">保存设置</div>
                </div>
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 创建客户 -->

    <!-- 查看客户 -->
    <div ref="rightPanel" v-if="show2" class="rightPanel-container show">
          <div class="rightPanel-background" />
          <div style="height:1100px;width: 656px;position: fixed;top: 0;right: 0;z-index:99999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel">
                <div class="Rig_1">查看客户<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div style="margin:0 0 18px 24px ">
                  <el-menu
                    :default-active="activeIndex"
                    class="el-menu-demo"
                    mode="horizontal"
                    @select="handleSelect">
                    <el-menu-item index="1">销售线索</el-menu-item>
                    <el-menu-item index="2">客户管理</el-menu-item>
                    <el-menu-item index="3">跟进记录</el-menu-item>
                  </el-menu> 
                </div>
                <div v-if="info_Show == 0">
                  <div class="Rig_A">
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px">姓名</div>
                                <div class="Rtty">
                                <el-input disabled v-model="input" placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:8px">联系方式</div>
                                <div class="Rtty">
                                <el-input disabled v-model="input" placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="overflow:hidden">
                        <div style="width:50%;float:left">
                            <div class="Rig_D">
                                <div class="Rig_Ddd" style="margin-bottom:9px">需求来源</div>
                                <el-select v-model="value1" placeholder="请选择" disabled>
                                <el-option  v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width:50%;float:left">
                            <div class="Rig_D ">
                                <div class="Rig_Ddd" style="margin-bottom:9px">需求价值</div>
                                <el-select v-model="value1" placeholder="请选择" disabled>
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>

                    <div class="Rig_D Tigtgg" >
                      <div class="Rig_Ddd" style="margin-bottom:9px">考试类型：</div>
                      <div class="Rtty" style="clear:both">
                        <el-select v-model="value1" placeholder="请选择" disabled>
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                      </div>
                    </div> 
                    <div class="Rig_D">
                        <div class="Rig_Ddd" style="margin-bottom:9px">考试类型：</div>
                        <div class="Rtty">
                            <el-input type="textarea" style="" v-model="form.desc" disabled></el-input>           
                        </div>
                                  
                    </div>
                    <div>
                      <div class="Rig_Ddd" style="margin-bottom:9px">图片线索（选填）</div>
                      <div class="Rtty TUUu" style="clear:both">
                        <el-upload 
                          disabled
                          action="https://jsonplaceholder.typicode.com/posts/"
                          list-type="picture-card"
                          :on-preview="handlePictureCardPreview"
                          :on-remove="handleRemove">
                          <i class="el-icon-plus"></i>
                          <div class="el_asff">上传图片</div>
                        </el-upload>
                        <el-dialog>
                          <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="info_Show == 1">
                    <el-scrollbar style="height:100%">
                      <div class="rightPanel">
                        <div class="Rig_A">
                        <div style="overflow:hidden">
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px"> 
                                      <span style="color:#FF4D4F">* </span>姓名</div>
                                    <div class="Rtty">
                                      <el-input v-model="input" placeholder="请输入考勤组名称，比如：行政部门考勤" class="El_ii"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 性别</div>
                                    <el-select v-model="value1" placeholder="请选择">
                                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div style="overflow:hidden">
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 联系方式</div>
                                    <div class="Rtty">
                                      <el-input v-model="input" placeholder="请填写客户手机号码" class="El_ii"></el-input>
                                    </div>
                                </div>
                            </div>
                            <div style="width:50%;float:left">
                                <div class="Rig_D ">
                                    <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 微信号（选填）</div>
                                    <div class="Rtty">
                                      <el-input v-model="input" placeholder="请填写客户微信号" class="El_ii"></el-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="overflow:hidden">
                          <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 工作单位（选填）</div>
                                    <div class="Rtty">
                                      <el-input v-model="input" placeholder="请填写线索客户工作单位" class="El_ii"></el-input>
                                    </div>
                                </div>
                          </div>
                        </div>
                        <div class="laushd">来源信息</div>
                        <div style="overflow:hidden">
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 客户来源</div>
                                    <el-select v-model="value1" placeholder="请选择">
                                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>  
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px"><span style="color:#FF4D4F">* </span> 客户价值</div>
                                    <el-select v-model="value1" placeholder="请选择">
                                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div style="overflow:hidden">
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px">办理时间（选填）</div>
                                    <div class="Rtty">
                                      <el-input v-model="input" placeholder="请选择办理时间" class="El_ii"></el-input>
                                    </div>
                                </div>
                            </div>  
                            <div style="width:50%;float:left">
                                <div class="Rig_D">
                                    <div class="Rig_Ddd" style="margin-bottom:8px">报单情况</div>
                                    <el-select v-model="value1" placeholder="请选择">
                                      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="Rig_D Tigtgg" >
                          <div class="Rig_Ddd" style="margin-bottom:9px"><span style="color:#FF4D4F">* </span> 需求项目（可多选）</div>
                          <div class="Rtty" style="clear:both">
                            <el-select v-model="value1" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                          </div>
                        </div> 
                        <div class="Rig_D Tigtgg" >
                          <div class="Rig_Ddd" style="margin-bottom:9px">客户标签（可多选）</div>
                          <div class="Rtty" style="clear:both">
                            <el-select v-model="value1" placeholder="请选择">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                          </div>
                        </div> 
                        <div class="laushd" style="margin-top:28px">附件</div>
                        <div class="Rig_D">
                            <div class="Rig_Ddd" style="margin-bottom:9px">描述（选填）</div>
                            <div class="Rtty">
                                <el-input type="textarea" style="" v-model="form.desc"></el-input>           
                            </div>    
                        </div>
                        <div>
                          <div class="Rig_Ddd" style="margin-bottom:9px">图片线索（选填）</div>
                          <div class="Rtty" style="clear:both">
                            <el-upload
                              action="https://jsonplaceholder.typicode.com/posts/"
                              list-type="picture-card"
                              :on-preview="handlePictureCardPreview"
                              :on-remove="handleRemove">
                              <i class="el-icon-plus"></i>
                              <div class="el_asff">上传图片</div>
                            </el-upload>
                            <el-dialog>
                              <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                          </div>
                        </div>
                        <div class="BCSZ" style="margin-right:24px">保存设置</div>
                        <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">保存设置</div>
                        </div>
                      </div>
                    </el-scrollbar>      
                </div>
                <div v-if="info_Show == 2">
                    <div class="Rig_A">
                      <div class="Rig_D Tigtgg R_texxt" >
                        <div class="TRRE">
                          <div style="margin:8px 0 9px 0;font-size: 14px;color: #333333;">全部进程信息</div>
                          <div style="margin:20px 24px 0 1px;">
                            <div id="time-line">
                              <div class="item" v-for="item in items" :key="item.index">
                                <div class="ytyt">
                                  <div class="item-index">{{ item.index }}</div>
                                  <div class="item-content">{{ item.content }}</div>
                                  <el-image v-if="item.img"
                                    :src="item.img" :fit="fit"></el-image>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="Rig_D" style="position: relative;">
                            <div class="Rig_Ddd" style="margin-bottom:9px"> <span style="color:#FF4D4F">*</span> 文字记录</div>
                            <div class="Rtty">
                                <el-input type="textarea" style="" v-model="form.desc" placeholder="请将每次沟通、维护、跟单、咨询等相关信息记录在这里，为了提高效率
① 选择常用语更新记录
② 手动输入文字信息
③ 将重要的凭证、历史沟通、其他社交媒体截图等在下方上传保存"></el-input>           
                            </div>     
                          <div class="Rtty Riuo fdgfg" style="clear:both;">
                            <el-select v-model="value1" placeholder="请选择常用语">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                            </el-select>
                            <div style="margin:4px 23px 0 0;float:right">
                                <el-checkbox v-model="checked_kehu" label="同步给客户"></el-checkbox>
                            </div>
                          </div>
                        </div>  

                        <div>
                          <div class="Rig_Ddd" style="margin:28px 0 9px 0">图片线索（选填）</div>
                          <div class="Rtty TUUu" style="clear:both">
                            <el-upload 
                              action="https://jsonplaceholder.typicode.com/posts/"
                              list-type="picture-card"
                              :on-preview="handlePictureCardPreview"
                              :on-remove="handleRemove">
                              <i class="el-icon-plus"></i>
                              <div class="el_asff">上传图片</div>
                            </el-upload>
                            <el-dialog>
                              <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                          </div>
                        </div>
                        <div class="Rtty">
                          <div class="BCSZ" style="margin-right:24px">保存设置</div>
                          <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;">保存设置</div>
                        </div>
                      </div> 

                    </div>
                
                </div> 
              </div>
            </el-scrollbar>
          </div>
    </div>
    <!-- 查看客户 -->

  </div>
</template>

<script>
import { fetchData } from "../api/index";
export default {
  name: "basetable",
  data() {
    return {
      TA_name1:'多少',
      TA_name2:'销售线索',
      TA_name3:'',
      Show:false,
      Inpup:'',
      reverse: true,
      items: [
        {
          index: "2020-8-14 13:20:30",
          content: "开始毕设。。",
          img:'https://profile.csdnimg.cn/6/1/6/3_will__be'
        },
        { index: "2020-8-15 13:20:30", content: "写前端。。" },
        {
          index: "2020-8-16 13:20:30",
          content: "还在写前端。。"
        },
        {
          index: "2020-8-17 13:20:30",
          content: "仍在写前端。。"
        },
        { index: "2020-8-18 13:20:30", content: "不想写前端。。" },
        { index: "2020-8-19 13:20:30", content: "还得写前端。。。。。" }
      ],
      info_Show:0,
      checked_kehu:false,
      activeIndex: "2",
      dialogImageUrl: '',


      show1:false,
      show2:true,

      radio:'1',

      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      tableData: [
          {
              id:'01',
              name:'豆腐网销售考勤小队',
              people:'预设',
              banci:'固定上下班',
              time:'周六、周日休息周一、周二、周三、周四、周五班次默认班次: 09:00-18:00',
              num:15,
              fangs:'wifi打卡',
              inpu:0,
              switch:false

          },
                      {
              id:'01',
              name:'豆腐网销售考勤小队',
              people:'自定义',
              banci:'固定上下班',
              time:'周六、周日休息周一、周二、周三、周四、周五班次默认班次: 09:00-18:00',
              num:15,
              fangs:'wifi打卡',
              inpu:1,
              switch:true

          },
      ],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1
    };
  },
  methods: {
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    AddXs() {
      console.log('123')
      this.Show = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    Add_kq() {
        console.log('re')
        console.log('re')
    },
    // handleSelect(key, keyPath) {
    //   console.log(key, keyPath);
    // },
    handleSelect_top (e) {
      if (e == 1) {
        this.$router.push('/Manage');
        console.log('jhjh')
      } else if (e == 3) {
        this.$router.push('/Order');
      } else if (e == 4) {
        this.$router.push('/Contract');
      }
    },
    handleSelect(key, keyPath) {
      console.log(key);
      console.log(keyPath);
      if (key == 1) {
        this.info_Show = 0;
      } else if(key == 2){
        this.info_Show = 1
      } else {
        this.info_Show = 2
      }
    },
    getData() {
        fetchData(this.query).then(res => {
            console.log(res);
            this.tableData = res.list;
            this.pageTotal = res.pageTotal || 50;
        });
    },
    // 触发搜索按钮
    handleSearch() {
        this.$set(this.query, "pageIndex", 1);
        this.getData();
    },
    // 删除操作
    handleDelete(index) {
        // 二次确认删除
        this.$confirm("学员离职后，将无法登录学习平台！学员与平台绑定的微信也自动解绑。确定要为“张浩”办理离职手续吗？", "删除", {
            // type: "warning"
        })
            .then(() => {
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
            })
            .catch(() => {});
        
    },
    // 多选操作
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    delAllSelection() {
        const length = this.multipleSelection.length;
        let str = "";
        this.delList = this.delList.concat(this.multipleSelection);
        for (let i = 0; i < length; i++) {
            str += this.multipleSelection[i].name + " ";
        }
        this.$message.error(`删除了${str}`);
        this.multipleSelection = [];
    },
    // 编辑操作
    handleEdit(index, row) {
        this.idx = index;
        this.form = row;
        this.editVisible = true;
    },
    handleEdit2(e) {
      console.log(e.row.inpu)
    },
    // 保存编辑
    saveEdit() {
        this.editVisible = false;
        this.$message.success(`修改第 ${this.idx + 1} 行成功`);
        this.$set(this.tableData, this.idx, this.form);
    },
    // 分页导航
    handlePageChange(val) {
        this.$set(this.query, "pageIndex", val);
        this.getData();
    }
  },
};
</script>
