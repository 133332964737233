import axios from 'axios';

// 是否正在刷新的标记
let isRefreshing = false
let requests = []

// 创建一个实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, 
    timeout: 5000 // request timeout
})

// 从localStorage中获取token
function getLocalToken () {
    const token = window.localStorage.getItem('token')
    return token
}

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
service.setToken = (token) => {
    instance.defaults.headers['X-Token'] = token
    window.localStorage.setItem('token', token)
}

//获取新的token请求
function refreshToken () {
    return service.post('/admin/index/login').then(res => res.data.data.userinfo.token)
}

// 拦截返回的数据
service.interceptors.response.use(response => {
    // 接下来会在这里进行token过期的逻辑处理
    const { code } = response.data
    alert(response.data)

        // 说明token过期了,获取新的token
       if (code === 401) {
           const config = response.config
           //判断一下状态
          if( !isRefreshing ){
              //修改状态，进入更新token阶段
              isRefreshing = true
              // 获取当前的请求
              return refreshToken().then(res => {
              // 刷新token成功，将最新的token更新到header中，同时保存在localStorage中
                const { token } = res.data.data.userinfo.token
                localStorage.setItem("accessToken", token);
                
                //重置失败请求的配置
                config.headers['X-Token'] = token
                config.baseURL = ''
                
                //已经刷新了token，将所有队列中的请求进行重试
                requests.forEach(cb => cb(token))
                // 重试完了别忘了清空这个队列
                requests = []
                
                return service(config)
              }).catch( res=>{
                  //重新请求token失败，跳转到登录页
                  window.location.href = '/Login '
              } ).finally( ()=>{
                  //完成之后在关闭状态
                  isRefreshing = false
              } )
          } else{
               // 正在刷新token，返回一个未执行resolve的promise
               return new Promise((resolve) => {
               // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
                   requests.push((token) => {
                        config.baseURL = ''
                        config.headers['X-Token'] = token
                        resolve(instance(config))
                      })
                })
          }
       }
    return response
  }, error => {
    return Promise.reject(error)
  })



export default service;
